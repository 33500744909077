<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";


/**
 * Form validation component
 */
export default {
  page: {
    title: "Actualités",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Actualités",
      tmpCoverFile: null,
      file: null,
      fileModif: null,
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Ajouter",
          active: true,
        },
      ],
      actualitePlan: {

        text: "",
        link: "",
        path: "",
      },
      actualitePlanModif: {
       
        text: "",
        link: "",
        path: "",
       
      },
      actualiteRowInfo: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      bonPlan: [],
      actualiteActif: [],
      actualiteDes: [],
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "title", sortable: true, label: "Titre" },
        { key: "date", sortable: true, label: "Date de création" },
        { key: "link", sortable: true, label: "Site web" },
  

        { key: "action" },
      ],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      showDelete: false,
      showEdit: false,
      showdisable: false,
      showactive: false,
      totalActualite:0,
      totalActualiteInactif:0
    };
  },


  mounted() {
    this.init();
  },

  methods: {
    async init() {
      const listActualite = await apiRequest("GET", "actualite", undefined, false);
console.log(listActualite.data);
      if (listActualite && listActualite.data) {
 
        const actualiteTAble = listActualite.data.actualite.map(actualite => {
          return {
            title: actualite.text,
            id: actualite.id,
            link: actualite.link,
            photo:actualite.photo,
            visible: actualite.visible,
            date: new Date(actualite.dateCreation).toLocaleString("fr-FR"),
          
          };
        });
        this.actualite = actualiteTAble;
       
        this.actualiteActif = [];
        this.actualiteDes = [];

        for (let i = 0; i < this.actualite.length; i++) {
          if (this.actualite[i].visible == true) {
            this.actualiteActif.push(this.actualite[i]);
            this.totalActualiteActif= this.actualiteActif.length
          } else {
            this.actualiteDes.push(this.actualite[i]);
            this.totalActualiteInactif=this.actualiteDes.length;
          }
        }
      }
    },
    editRow(row) {
      this.showEdit = true;
      this.actualitePlanModif.text = row.title;
      this.actualitePlanModif.link = row.link;
 
      this.actualitePlanModif.id = row.id;
      this.actualitePlanModif.visible = row.visible;
    },
    desactiverRow(row) {
      this.showdisable = true;
      this.actualiteRowInfo = row;
    },
    activerActualite(row) {
      this.showactive = true;
      this.actualiteRowInfo = row;
    },
    deleteRow(row) {
      this.showDelete = true;
      this.actualiteRowInfo = row;
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      this.$v.$touch();
    },

    tooltipForm() {
      this.submitform = true;
      this.formSubmit();
      this.$v.$touch();
    },

    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    handleFileUploadModif() {
      this.fileModif = this.$refs.files.files[0];
    },

    async submitactualitePlan() {
      let formData = new FormData();
      formData.append("text", this.actualitePlan.title);
      formData.append("link", this.actualitePlan.link);

      formData.append("file", this.file);
      const returnActualite = await apiRequest("POST", "actualite", formData, true);

      if (returnActualite && returnActualite.data) {
        this.showEdit = false;
        this.init();
      }
    },
    async submitactualitePlanModif() {
      let formData = new FormData();
      formData.append("id", this.actualitePlanModif.id);
      formData.append("text", this.actualitePlanModif.text);
      formData.append("path", this.actualitePlanModif.path);
      formData.append("link", this.actualitePlanModif.link);
      formData.append("file", this.files);
    

      const returnActualiteModif= await apiRequest("PUT", "actualite", formData, true);

   
      if (returnActualiteModif && returnActualiteModif.data) {
        this.showEdit = false;
        this.init();
      }
    },
    async desactivePlan() {
      this.showdisable = false;
      const returnActualitDeactivate = await apiRequest(
        "POST",
        "actualite-off",
        { id: this.actualiteRowInfo.id, visible: false },
        false
      );

      if (returnActualitDeactivate && returnActualitDeactivate.data) {
        this.init();
      }
    },
    async activePlan() {
      this.showactive = false;
      const returnActualitActive = await apiRequest(
        "POST",
        "actualite-off",
        { id: this.actualiteRowInfo.id, visible: true },
        false
      );

      if (returnActualitActive&& returnActualitActive.data) {
        this.init();
      }
    },
    async deletePlan() {
      this.showDelete = false;

      const returnActualitDelete = await apiRequest(
        "POST",
        "actualite-supp",
        { id: this.actualiteRowInfo.id },
        false
      );
      if (returnActualitDelete && returnActualitDelete.data) {
        this.init();
      }
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      this.$v.$touch();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <form
              enctype="multipart/form-data"
              @submit.prevent="submitactualitePlan"
            >
             
              <div class="form-group">
                <label for="tite">Texte</label>
                <!--<input
                  type="text"
                  id="title"
                  class="form-control"
                  placeholder="Sous-titre"
                  v-model="actualitePlan.soustitre"
                />-->
                <textarea
                  v-model="actualitePlan.title"
                  class="form-control"
            
                  placeholder="Texte"
                ></textarea>
              </div>
              <div class="form-group">
                <label for="link">Lien </label>
                <input
                  type="url"
                  id="link"
                  class="form-control"
                  placeholder="Site web"
                  v-model="actualitePlan.link"
                />
              </div>
              <div class="form-group">
                <label for="tite">Image</label>
                <input
                  type="file"
                  id="file"
                  ref="file"
                  v-on:change="handleFileUpload"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <button class="btn text-success-save float-right">
                  Ajouter
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <h5>Gérer</h5>
            </div>
            <form>
              <b-tabs nav-class="nav-tabs-custom">
                <b-tab title-link-class="p-3">
                  <template v-slot:title>
                    <a class="font-weight-bold active">Actualités actives</a>
                  </template>
                  <div class="row mt-4">
                    <div class="col-sm-12 col-md-6">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          Afficher&nbsp;
                          <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                          ></b-form-select
                          >&nbsp;entrées
                        </label>
                      </div>
                    </div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                      <div
                        id="tickets-table_filter"
                        class="dataTables_filter text-md-right"
                      >
                        <label class="d-inline-flex align-items-center">
                          Rechercher:
                          <b-form-input
                            v-model="filter"
                            type="search"
                            class="form-control form-control-sm ml-2"
                          ></b-form-input>
                        </label>
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <div class="table-responsive">
                    <b-table
                      class="table-centered"
                      :items="actualiteActif"
                      :fields="fields"
                      responsive="sm"
                      :per-page="perPage"
                      :current-page="currentPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                    >
                      <template v-slot:cell(action)="row">
                        <a
                          href="javascript:void(0);"
                          class=" text-primary"
                          v-b-tooltip.hover
                          @click="editRow(row.item)"
                          title="Editer"
                        >
                          <i class="mdi mdi-pencil font-size-18"></i>
                        </a>

                        <a
                          href="javascript:void(0);"
                          class="text-success"
                          v-b-tooltip.hover
                          @click="desactiverRow(row.item)"
                          title="Désactiver"
                        >
                          <i class="mdi mdi-close font-size-18"></i>
                        </a>
                        <a
                          href="javascript:void(0);"
                          class="text-danger"
                          @click="deleteRow(row.item)"
                          v-b-tooltip.hover
                          title="supprimer"
                        >
                          <i class="mdi mdi-trash-can font-size-18"></i>
                        </a>
                      </template>
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div
                        class="dataTables_paginate paging_simple_numbers float-right"
                      >
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="totalBonPlan"
                            :per-page="perPage"
                            :rows="bonPlanActif"
                          ></b-pagination>
                        </ul>
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                </b-tab>

                <b-tab title-link-class="p-3">
                  <template v-slot:title>
                    <a class="font-weight-bold active"
                      >Liste des actualités inactives</a
                    >
                  </template>
                  <div class="row  d-flex justify-content-between align-items-center mt-4">
                    <div class="p-3">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          Afficher&nbsp;
                          <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                          ></b-form-select
                          >&nbsp;entrées
                        </label>
                      </div>
                    </div>
                    <!-- Search -->
                    <div class="inputCol p-3">
                    <div class="input">
                      <input
                        type="search"
                        id="serchInput"
                        placeholder="Rechercher..."
                        v-model="filter"
                      />
                    </div>
                  </div>
                    <!-- End search -->
                  </div>
                  <div class="table-responsive">
                    <b-table
                      class="table-centered"
                      :items="actualiteDes"
                      :fields="fields"
                      responsive="sm"
                      :per-page="perPage"
                      :current-page="currentPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                    >
                      <template v-slot:cell(action)="row">
                        <a
                          href="javascript:void(0);"
                          class=" text-primary"
                          v-b-tooltip.hover
                          @click="editRow(row.item)"
                          title="Editer"
                        >
                          <i class="mdi mdi-pencil font-size-18"></i>
                        </a>

                        <a
                          href="javascript:void(0);"
                          class="text-success"
                          v-b-tooltip.hover
                          @click="activerActualite(row.item)"
                          title="Activer"
                        >
                          <i class="mdi mdi-check font-size-18"></i>
                        </a>
                        <a
                          href="javascript:void(0);"
                          class="text-danger"
                          @click="deleteRow(row.item)"
                          v-b-tooltip.hover
                          title="supprimer"
                        >
                          <i class="mdi mdi-trash-can font-size-18"></i>
                        </a>
                      </template>
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div
                        class="dataTables_paginate paging_simple_numbers float-right"
                      >
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="totalActualite"
                            :per-page="perPage"
                            :rows="bonPlanDes"
                          ></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </form>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-backdrop"
      centered
      v-model="showDelete"
      title="Voulez-vous vraiment supprimer ce plan ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showDelete = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="deletePlan"
          >Oui</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showdisable"
      title="Voulez-vous vraiment désactiver ce bon plan ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showdisable = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="desactivePlan"
          >Oui</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showactive"
      title="Voulez-vous vraiment activer bon plan ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showactive = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="activePlan"
          >Oui</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-center"
      centered
      size="xl"
      v-model="showEdit"
      title="Editer une actualité"
      title-class="font-18"
      hide-footer
    >
      <form
        enctype="multipart/form-data"
        @submit.prevent="submitactualitePlanModif"
      >
        <div class="form-group">
          <label for="tite">Texte</label>
          <input
            type="text"
            id="title"
            class="form-control"
            placeholder="titre"
            v-model="actualitePlanModif.text"
          />
        </div>
       
        <!-- <div class="form-group">
          <label for="tite">texte</label>
          <input
            type="text"
            id="title"
            class="form-control"
            placeholder="sous titre"
            v-model="actualitePlanModif.text"
          />
        </div> -->
        <div class="form-group">
          <label for="tite">Lien du bon plan</label>
          <input
            type="url"
            id="title"
            class="form-control"
            placeholder="lien du bon plan"
            v-model="actualitePlanModif.link"
          />
        </div>
        <div class="form-group">
          <label for="title">Image</label>
          <input
            type="file"
            id="files"
            ref="files"
            v-on:change="handleFileUploadModif"
            class="form-control"
          />
        </div>
        <div class="form-group">
          <button class="btn text-success-save float-right" v-if="actualitePlanModif.id !=null">Modifier</button>
       
        </div>
      <!--   <div class="form-group">
          <label for="tite">image</label>
          <input type="file" id="title" class="form-control" />
        </div> -->
        <div class="form-group" v-if="actualitePlanModif.id ==null">
          <button class="btn text-success-save float-right">Ajouter</button>
        </div>
      </form>
    </b-modal>
  </Layout>
</template>

<style>
#showPassword {
  width: 35px;
  height: 35px;
}



thead {
  background: #3AAA35;
}thead div{
  color:#fff;
}

.input {
  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 35px;
  bottom: 0;
  flex: 60%;
margin-bottom: 5px;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}
.input input:focus {
  border: 3px solid #3aaa35;
}
.text-success-save{
    background: #3AAA35;
    outline: none;
    border:none;
    color:#fff;
}
.inputCol {
  position: relative;
}


.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #3aaa35;
  border-color: #3aaa35;
}
.paging_simple_numbers {
  background: #3AAA35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
} .paging_simple_numbers ul li{
  color:white!important;
}


.paging_simple_numbers ul li button{
  color:#fff!important;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}

.nav-tabs .nav-link a {
  color: rgba(44, 56, 74, 0.95);
}
.nav-tabs-custom .nav-item .nav-link.active a {
  color: #fff!important;
  font-weight: 600 !important;
  vertical-align: middle;
}
.nav-tabs-custom .nav-item .nav-link.active {
  font-weight: 600;
  background: #3AAA35!important;
  border-bottom: 1px solid #000000!important;
}
.nav-tabs-custom .nav-item .nav-link::after {
  border-bottom: 1px solid #000000!important;
}

</style>
